import './Contacts.scss';
import contactBg from '../assets/img/elements-17.png';
import locationIcon from '../assets/img/location-icon.png';
import phoneIcon from '../assets/img/phone-icon.png';
import emailIcon from '../assets/img/email-icon.png';
import AosDiv from './utils/AosDiv';
import validator from 'validator';
import { useTranslation } from 'react-i18next';
import { useRef, useEffect, useState } from 'react';
import General from './axios/General';
import Button from 'react-bootstrap/Button';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import { staticBaseURL } from './config';

const Contact = ({ contactUsRef, width }) => {
	const { t, i18n } = useTranslation();
	const ref = useRef(null);

	useEffect(() => {
		contactUsRef(ref);
	}, [contactUsRef]);

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState(1);
	const [emailErrorMessage, setEmailErrorMessage] = useState('');
	const [message, setMessage] = useState('');
	const [messageError, setMessageError] = useState(1);
	const [messageErrorMessage, setMessageErrorMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isSent, setIsSent] = useState(false);
	const [status, setStatus] = useState(0);
	const [successMessage, setSuccessMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [token, setToken] = useState('');

	const validateEmail = (email) => {
		if (validator.isEmail(email)) {
			setEmailError(1);
			return true;
		} else {
			setEmailError(0);
			setEmailErrorMessage('Please enter a valid email');
			return false;
		}
	}
	const validateMessage = (message) => {
		if (message.length > 10) {
			setMessageError(1);
			return true;
		} else {
			setMessageError(0);
			setMessageErrorMessage('The message must be at least 10 characters');
			return false;
		}
	}

	function onChange(value) {
		setToken(value);
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		setIsSent(false);
		if (name !== '' && email !== '' && message !== '') {
			if (token !== '') {
				try {
					if (validateEmail(email) && validateMessage(message)) {
						try {
							setIsLoading(true);
							new General().contactForm({
								name: name,
								email: email,
								message: message,
							}, (response) => {
								setIsSent(true);

								setIsLoading(false);

								setStatus(true);
								setSuccessMessage('Your message has been sent. We will contact you as soon as possible.');

								setEmail('');
								setName('');
								setMessage('');

							}, (error) => {
								setIsLoading(false);
								setStatus(false);
								setErrorMessage('Something went wrong, please try again later.');
								console.log(error.message);
							});
						} catch (error) {
							setIsLoading(false);
							setStatus(false);
							setErrorMessage('Something went wrong, please try again later.');
							console.log(error.message);
						}
					}
				} catch (error) {
					setErrorMessage('Something went wrong, please try again later.');
					console.log(error.message);
				}
			} else {
				setIsSent(true);
				setStatus(false);
				setErrorMessage('Please verify that you are not a robot.');
			}
		} else {
			setIsSent(true);
			setStatus(false);
			setErrorMessage('Please enter the required fields.');
		}
	}

	return (
		<section id='contact-container' ref={ref} className='contactContainer dflex'>
			{width > 480 && <img className='contactBg' src={staticBaseURL + "contact_us_bg.png"} alt='' />}
			<div className='contactHeader'>
				<h2 className='title COMMON_TITLE_BOLD m-auto'>{t('Contact_Us')}</h2>
				<h3>{t('We_are_here_to_help')}</h3>
			</div>
			<div className='dflex contactUsBody'>
				<div className='contactUsForm'>
					{
						isSent && (
							status ?
								<div className='Alert-green-Message'>
									{successMessage}
								</div>
								:
								<div className='Alert-red-Message'>
									{errorMessage}
								</div>
						)
					}
					<form>
						<div>
							<input
								className='form-control contact-input'
								placeholder={"👋 "+t('name')}
								type='text'
								disabled={isLoading}
								onInput={e => setName(e.target.value)}
								name='name'
								id='name'
								required
							/>
						</div>
						<div>
							{emailError === 0 && <p className="error-message">{emailErrorMessage}</p>}
							<input
								className='form-control contact-input'
								placeholder={"📧 "+t('email')}
								type='text'
								disabled={isLoading}
								name='email'
								id='email'
								onInput={e => setEmail(e.target.value)}
								required
							/>
						</div>
						<div>
							{messageError === 0 && <p className="error-message">{messageErrorMessage}</p>}
							<textarea
								style={{ height: '160px' }}
								className='form-control contact-input'
								placeholder={"💬 "+t('message')}
								type='textarea'
								name='message'
								id='message'
								disabled={isLoading}
								onInput={e => setMessage(e.target.value)}
								required
							/>
						</div>
						<ReCAPTCHA
							sitekey="6LdihjIgAAAAAGyKYSDFU3sFaR7PB3adBuUAbUH1"
							onChange={onChange}
						/>
						<Button
							// loading={isLoading}
							className='contactSendButton'
							onClick={handleSubmit}
							disabled={isLoading}
						>
							{isLoading ? 'Sending...' : 'Send'}
						</Button>
					</form>
				</div>
				<div className='generalInformation'>
					<div className={i18n === "en" ? "infoItem" : "infoItem-ar"}>
						<img width={50} height={50} src={staticBaseURL + "address.png"} alt='' />
						<div>
							<p className='title'>{t('Address')}</p>
							<p className='description'>
								{t('Address_description')}
							</p>
						</div>
					</div>
					<div className={i18n === "en" ? "infoItem" : "infoItem-ar"}>
						<img width={50} height={50} src={staticBaseURL + "phone.png"} alt='' />
						<div>
							<p className='title'>{t('phone')}</p>
							<p className='description'>+971 522 088 516</p>
						</div>
					</div>
					<div className={i18n === "en" ? "infoItem" : "infoItem-ar"}>
						<img width={50} height={50} src={staticBaseURL + "email.png"} alt='' />
						<div>
							<p className='title'>{t('email')}</p>
							<p className='description'>
								hello@eflow.app
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contact;
