import './WhatWeWork.scss';
import { useState } from "react";
import Slider from 'react-slick';
import imgBg from '../assets/img/work/what_we_work.png';
import img01 from '../assets/img/course-34.png';
import img02 from '../assets/img/course-35.png';
import img03 from '../assets/img/course-36.png';
import img04 from '../assets/img/course-37.png';
import img05 from '../assets/img/course-38.png';
import img06 from '../assets/img/course-39.png';
import img07 from '../assets/img/course-40.png';
import img08 from '../assets/img/course-41.png';
import img09 from '../assets/img/course-42.png';
import { useTranslation } from 'react-i18next';
import {staticBaseURL} from './config';
import {useEffect } from 'react';

const images = ["course01.png","course02.png","course03.png","course04.png","course05.png","course06.png","course07.png","course08.png","course09.png"];

const WhatWeWork = () => {

    const { t, i18n } = useTranslation();

    const [ImageIndex, setImageIndex] = useState(0);
    const [rtl, setRtl] = useState(false);
    
    useEffect(() => {
        { i18n.language === "ar" ? setRtl(true) : setRtl(false) }
    }, [i18n.language])

    const slickSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true,
        rtl: rtl,
        centerMode: true,
        prevArrow: null,
        nextArrow: null,
            arrows: false,
                responsive: [
                    {
                        breakpoint: 1000,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: false
                        }
                    },
                    {
                        breakpoint: 750,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            initialSlide: 1,
                            dots: false
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: false
                        }
                    }
                ],
                    beforeChange: (current, next) => setImageIndex(next)
};

return (
    <div id='portfolio-container' className='Work-container'>
        <section className='section-work' style={{ position: 'relative' }}>
        <p className='COMMON_TITLE_BOLD wc-title'>{t('featured_courses')}</p>
            <img className='work-bg' src={staticBaseURL + "course_bg.png"} alt='' />
            <div className='work-container'>
                <Slider {...slickSettings} >
                    {images.map((img, idx) => (
                        <div key={idx} className={idx === ImageIndex ? "slide activeSlide" : "slide"}>
                            <img className='slider-image' key={idx} src={staticBaseURL + img} alt={img} />
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    </div>
);
};
export default WhatWeWork;