import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import NewHeader from './components/header/NewHeader';
import PrivacyPolicy from './PrivacyPolicy';
import About from './About';
import Singup from './components/Signup';
import { useWindowWidthAndHeight } from './components/utils/useWindowWidthAndHeight';
import { staticBaseURL } from './components/config';

const Parent = () => {
	const [position, setPosition] = useState('');
	const [width] = useWindowWidthAndHeight();
	return (
		<Router>
			<NewHeader headerSelection={(e) => {

				if (
					window.location.href.includes("privacy")
					|| window.location.href.includes("about")
					|| window.location.href.includes("signup")
				) {
					window.location.href = "/"
				} else if (e === "About") {
					window.location.href = "/about"
				} else {
					setPosition(e);
				}
			}} />
			<Routes>
				<Route exact path='/' element={<App position={position} />} />
				<Route exact path='/privacy' element={<PrivacyPolicy width={width} />} />
				<Route exact path='/about' element={<About width={width} />} />
				<Route exact path='/signup' element={<Singup width={width} />} />
			</Routes>
		</Router>
	);
};
export default Parent;