import axios from 'axios';
import {store} from './store';
import userActions from './userActions';

const createAxiosSingleton = () => {
    let currentToken, lastTokenUpdate = Date.now();

    let axiosSingleton = axios.create({
        //baseURL: "https://nef.cherpa.io/api/v1",
        //baseURL: "https://rilbnapi.cherpa.school/api/v1",
        baseURL: "https://devapi2.eflow.app/api/v1",

        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': '*'
        }
    });

    axiosSingleton.interceptors.request.use(
        function (config) {
            const token = localStorage.getItem('token');

            if (token) {
                currentToken = token;
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        },
        function (error) {
            return Promise.reject(error.data.response);
        }
    );

    axiosSingleton.interceptors.response.use(
        (response) => {
            return response;
        }, (error) => {
            if (error.response.status === 401) {
                const headers = {
                    'Authorization': 'bearer ' + currentToken
                }

                const originalRequest = error.config;

                axiosSingleton
                    .post('/reissuetoken', null, {headers: headers})
                    .then((response) => {
                        if (response && response.data && response.data.token) {
                            lastTokenUpdate = Date.now();

                            let token = response.data.token;

                            currentToken = token;

                            localStorage.setItem('token', token);
                            localStorage.setItem('lastTokenUpdate', lastTokenUpdate);

                            originalRequest.headers['Authorization'] = 'Bearer ' + token;

                            // axios(originalRequest);
                            window.location.reload();
                        } else {
                            //console.log("no token received");
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 403) {
                            store.dispatch(userActions.performLogout());

                            window.location.reload();
                        }

                        let now = Date.now();

                        lastTokenUpdate = parseInt(localStorage.getItem('lastTokenUpdate'));

                        if (now - lastTokenUpdate > (60 * 1000)) {
                            store.dispatch(userActions.performLogout());
                        } else {
                            axios(originalRequest);
                        }
                    });
            } else {
                return Promise.reject(error);
            }
        }
    );

    return axiosSingleton;
};

export default (action, uri, data, onSuccessCallback, onErrorCallback) => {
    let AxiosSingleton = createAxiosSingleton();

    return AxiosSingleton[action](uri, data)
        .then((response) => onSuccessCallback(response ? (response.data ? response.data : response) : response))
        .catch((error) => onErrorCallback(error));
};
