export const TRANSLATIONS_EN = {
	future: 'Future of',
	conversational: 'Flow',
	mobile: 'Learning',
	frictionless: 'AI-Powered Conversational Learning',
	bookADemo: 'Book a Demo',
	watch_video: 'Watch Video',
	our_clients: 'Trusted by',
	learner_cyntric_title: 'A Learner-Centric Educational Platform',
	youth_and_adults: 'For Youth And Adults',
	adaptive: 'Adaptive',
	social: 'Social',
	learner: 'Learner',
	psycographic: 'Psychographics',
	emotions: '& Emotions',
	conversational: 'Flow into',
	learn_more: 'Learn more',
	corporate_learning: 'Corporate Learning',
	and_development: '& Development',
	youth: 'Youth',
	learning: 'Learning',
	lead_generation: 'Lead Generation',
	and_data_collection: '& Data Collection',
	use_cases: 'Use Cases',
	learn_more_how_flow_can_enhance_your_life: 'Learn more how eFlow can enhance your objectives.',
	Request_Quote: 'Request Quote',
	natural: '& Natural',
	learning: 'Learning',
	seamlessly: 'Seamlessly',
	available: ' Available',
	highligh_integrable: '& Highly Integrable',
	various_apps: 'with various apps',
	create_now: 'Create Now',
	create_educational_experience: 'Create Educational Experiences',
	in_3_easy_steps: 'In 3 Easy Steps',
	step01_title: '1. Create your flow',
	step01_description: 'The messages sent support various images voice notes, links and documents and include +10 evaluation templates (MCQ. Drag and Drop, True False...)',
	step02_title: '2. Push to learners',
	step02_description: "eFlow's chatbot on WhatsApp, Facebook Messenger, Slack or other communication app communicates with each learner, analyzes their responses and replies back.",
	step03_title: '3. Track & Analyze Results',
	step03_description: 'Allow instructors to obtain analytics on the number of completed courses per student or group based on a number of demographics such as Country, Age, etc.',
	demo01: "Let's take 2 minutes and find out",
	demo02: 'if eFlow is right for you',
	behind_this_platform: 'About eFlow',
	about_us: 'About Us',
	team_txt: 'Technologists and educators on a common vision to ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.',
	prices_title: 'Affordable Pricing and Packages',
	prices_learner: '/learner',
	bronze: 'Bronze',
	silver: 'Silver',
	gold: 'Gold',
	Minimum_30_learners: 'Minimum 30 learners',
	Up_to_4_free_educator_accounts: 'Up to 4 free educator accounts',
	WhatsApp_shared_number: 'WhatsApp shared number',
	Telegram_shared_number: 'Telegram shared number',
	Live_tracking: "Live tracking",
	Advanced_insights_and_analytics: 'Advanced insights and analytics',
	Data_export_in_different_formats: 'Data export in different formats',
	messages_per_month: 'messages per month',
	Full_technical_support: 'Full technical support',
	Request_Access: 'Request Access',
	Recommended: 'Recommended',

	Minimum_1000_learners: 'Minimum 1,000 learners',
	Up_to_12_free_educator_accounts: 'Up to 12 free educator accounts',
	WhatsApp_dedicated_number: 'WhatsApp dedicated number',
	Telegram_dedicated_number: 'Telegram dedicated number',
	Live_tracking: 'Live tracking',
	Advanced_insights_and_analytics: 'Advanced insights and analytics',
	Full_technical_support: 'Full technical support',
	Full_educational_support: 'Full educational support',

	Contact: 'Contact',
	us: 'us',

	Minimum_5000_learners: 'Minimum 5,000 learners',
	unlimited_free_educator_accounts: 'unlimited free educator accounts',
	Dedicated_instructional_designer: 'Dedicated instructional designer',
	Hear_from_our_clients: 'Hear from our clients',
	eFlow_is_loved_by_clients: 'eFlow is loved by clients!',
	take_2_minutes: "Let's take 2 minutes and find",
	out_if_Flow_is_right_for_you: 'out if eFlow is right for you',

	Contact_Us: 'Contact Us',
	We_are_here_to_help: 'We would like to hear from you',
	message: 'Message',
	email: 'Email',
	name: 'Your name',

	Address: 'Address',
	Address_description: 'MBAM0144, Al Jazirah Al Hamra, Ras Al Khaimah, United Arab Emirates',
	phone: 'Phone',
	number: '+971 522 088 516',
	send: 'Send',

	eFlow_enables_interactive_microlearning_around_the_world: 'eFlow enables interactive microlearning around the world.',

	About_eFlow: 'About us',
	Our_Team: 'Our Team',
	Portfolio: 'Portfolio',
	Contact_informations: 'Contact Us',

	Features: 'Features',
	featured_courses: 'Featured Courses',
	Integrations: 'Integrations',
	Authoring_tools: 'Authoring tools',
	Add_ons: 'Add-ons',

	Legal: 'Legal',
	Terms_and_conditions: 'Terms and conditions',
	Privacy_policy: 'Privacy policy',

	Copyrights: 'Copyrights',
	All_rights_reserved: 'All rights reserved. Powered by eFlow FZ-LLC',

	q1: 'What languages are supported?',
	ans1: 'We support English, Arabic, French and Spanish. More languages are being supported rapidly.',
	q2: 'How many learners am I limited to?',
	ans2: 'Depending on your package, you can increase the number of learners you can manage on the platform.',
	q3: 'What do learners need to download to start learning?',
	ans3: 'Nothing, learners should only have WhatsApp, Telegram or Facebook messenger depending on your platform of choice. No other app is needed. ',
	q4: 'Does the teacher have to be online so students can learn?',
	ans4: 'No, eFlow provides an asynchronous learning platform automatd by our educational chatbot. Educators just have add the content to the platform and the automated chatbot will be always available to help learners learn the content.',
	q5: 'What type of content do you support?',
	ans5: 'We support a various number of messages from media messages like videos, images, voicenotes to multiple choice, true false questions ad interactive H5P content.',
	q6: 'How do I register?',
	ans6: 'Contact us on hello@eflow.app and we will send you more details to start using the platform.',

};
